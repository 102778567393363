import Notiflix from "notiflix";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card, CardBody, Col, Row } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import "../Authentication/login.css";
const SkeletonSales = ({ cards }) => {
	return (
		<CardBody>
			<h4>
				<Skeleton className="mt-3" />
			</h4>
			<Row>
				<Col sm="7">
					<p className="text-muted">
						<Skeleton className="mt-3" />
					</p>
					<p className="text-muted">
						<Skeleton />
					</p>
				</Col>
			</Row>
		</CardBody>
	);
};
class MonthlyEarning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			TotalSales: [],
			saleSseries1: [],
			loader: true,
			options: {
				chart: {
					width: "100%",
					type: "pie",
				},
				labels: ["Sales Value", " Today", "This Month", "This Year"],
				theme: {
					monochrome: {
						enabled: true,
					},
				},
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -5,
						},
					},
				},
				dataLabels: {
					formatter(val, opts) {
						const name = opts.w.globals.labels[opts.seriesIndex];
						return [name, val.toFixed(1) + "%"];
					},
				},
				legend: {
					show: false,
				},
			},
		};
		this.togglemodal.bind(this);
	}
	togglemodal = () => {
		this.setState((prevState) => ({
			modal: !prevState.modal,
		}));
	};
	componentDidMount() {
		// Notiflix.Loading.Init({
		// 	svgColor: "#777f80",
		// });
		// Notiflix.Loading.Custom("");
		//get sales count
		GetApiCall.getRequest("GetSalesTotalValuesCurrentMonth").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					TotalSales: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);
		// =========SalesRadialGraph==========
		PostApiCall.postRequest(
			{
				WhereClause: "",
			},
			"Get_DashboardSalesCard"
		).then((results) =>
			results.json().then((obj4) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						saleSseries1: [
							obj4.data[0].total_sales,
							obj4.data[0].today_sales,
							obj4.data[0].this_month_sales,
							obj4.data[0].this_year_sales,
						],
						CustomerData: obj4.data,
						loader: false,
					});
					Notiflix.Loading.Remove();
				}
			})
		);
	}
	capitalizeWords = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}; render() {
		return (
			<React.Fragment>
				{" "}
				<Card className="summery-size-comon">
					{this.state.loader == true ? (
						<>
							<SkeletonSales />
						</>
					) : (
						<CardBody>
							<h4>Sales Summary</h4>
							<Row>
								<Col sm="7">
									<p className="text-muted">
										For the Month of{" "}
										{this.state.TotalSales?.map((item, i) => {
											return item.month_name;
										})}
									</p>
									<h3>
										{this.state.TotalSales?.map((item, i) => {
											return "₹" + item.total_sales;
										})}
									</h3>
									<p className="text-muted">
										<span className="text-success mr-2">
											{" "}
											{this.state.TotalSales?.map((item, i) => {
												if (item.last_month_sales > item.total_sales) {
													return (
														(parseFloat(
															item.total_sales / item.last_month_sales
														).toFixed(4) *
															100 -
															100) *
														-1 +
														"%"
													);
												}
												if (item.last_month_sales < item.total_sales) {
													return (
														parseFloat(
															item.last_month_sales / item.total_sales
														).toFixed(4) *
														100 +
														"%"
													);
												}
											})}{" "}
											<i className="mdi mdi-arrow-up"></i>
											{this.state.TotalSales?.map((item, i) => {
												if (item.last_month_sales < item.total_sales) {
													return <i className="mdi mdi-arrow-up"></i>;
												}
												if (item.last_month_sales > item.total_sales) {
													return <i className="mdi mdi-arrow-down"></i>;
												}
											})}
										</span>{" "}
										From previous period
									</p>
								</Col>
								<Col sm="5" className="monthlyChart">
									<ReactApexChart
										options={this.state.options}
										series={this.state.saleSseries1}
										type="pie"
										height={210}
										width={300}
									/>
								</Col>
							</Row>
						</CardBody>
					)}
				</Card>
			</React.Fragment>
		);
	}
}
export default MonthlyEarning;
