import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row, Tooltip } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
// actions
import moment from "moment";
import Notiflix from "notiflix";
import { AiOutlineEye, AiOutlineMail, AiOutlineRetweet } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import loginlogo from "../../assets/images/login/Bechofylogowhite.png";
import PostApiCall from "../../ManageCustomerAPI";
import { apiError, loginUser } from "../../store/actions";
import "./login.css";
class Login extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isPasswordVisible: false,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      Email: "",
      Password: "",
      history: useHistory,
      clientId: null,
      clientLogo: null,
      clientName: null,
      rememberMe: null,
      tooltipOpen: false,
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  componentDidMount() {
    if (localStorage.getItem("RememberMe")) {
      this.setState({
        Email: localStorage.getItem("Email"),
        rememberMe: localStorage.getItem("RememberMe"),
      });
    }
  }
  // authenticate and login handler
  onClickLogin() {
    if (this.state.Email != "") {
      if (this.state.EmailRegex.test(this.state.Email)) {
        if (this.state.Password != "") {
          Notiflix.Loading.Dots("");
          PostApiCall.postRequest(
            {
              email: this.state.Email,
              password: this.state.Password,
              action: "Login",
              actiondate: moment().format("lll"),
            },
            "AuthenticateUser"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                localStorage.setItem(
                  "lastlogin",
                  JSON.stringify(moment().format("lll"))
                );
                localStorage.setItem("access", JSON.stringify(obj.token));
                localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
                localStorage.setItem("ClientDetails", JSON.stringify(obj.data));
                localStorage.setItem("RememberMe", this.state.rememberMe);
                localStorage.setItem("Email", this.state.Email);
                Notiflix.Loading.Remove();
                window.location.href = "/dashboard"; // route to dashboard
              } else {
                Notiflix.Loading.Remove();
                Notiflix.Notify.Failure(obj.data);
              }
            })
          );
        } else {
          Notiflix.Notify.Failure("Please enter password.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter valid email address.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter email address.");
    }
  }
  OnChangepwd(password) {
    this.setState({
      Password: password.target.value,
    });
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="account-pages">
          <Container fluid className="main-img">
            <div className="logo-content">
              <h1 className="text-white">Welcome to your</h1>
              <h1 className="text-white">Store Panel!</h1>
              <h3 className="text-white">Manage your Online Store</h3>
            </div>
            <div className="logo-parent">
              <a
                className="becofy-copyright"
                href="https://bechofy.in/"
                target="_blank"
              >
                <img src={loginlogo} className="logo-parent" />
              </a>
            </div>
            <div className="mt-5 text-center copyright-content">
              <p>
                © {new Date().getFullYear()}{" "}
                <a
                  className="becofy-copyright"
                  href="https://bechofy.in/"
                  target="_blank"
                >
                  Bechofy.in
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <Row className="justify-content-end card-align">
              <Col md={7} lg={5} xl={4} className="login-card">
                <Card className="overflow-hidden login-card-shadow">
                  <div className="Bechofy-bg-soft-pink"></div>
                  <CardBody className="pt-0 mb-4">
                    <div className="text-center mt-5">
                      {localStorage.getItem("RememberMe") === "true" ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("ClientDetails"))[0]
                              .fld_image
                          }
                          alt=""
                          className="usericon"
                        />
                      ) : (
                        <FaUserCircle
                          src={
                            localStorage.getItem("ClientDetails") !== null
                              ? JSON.parse(
                                localStorage.getItem("ClientDetails")
                              )[0].fld_image
                              : ""
                          }
                          className="usericon"
                        />
                      )}
                    </div>
                    <h1 className="text-center my-4">Sign in</h1>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="form-group">
                          <label>Email</label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text bg-white"
                                id="basic-addon1"
                              >
                                <AiOutlineMail className="icon-size" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={this.state.Email}
                              disabled={this.state.rememberMe}
                              onChange={(text) => {
                                this.setState({
                                  Email: text.target.value,
                                });
                              }}
                              type="email"
                            />
                            {localStorage.getItem("RememberMe") === "true" ? (
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text bg-white"
                                  id="basic-addon1"
                                >
                                  <AiOutlineRetweet
                                    onClick={() => {
                                      localStorage.clear();
                                      this.setState({
                                        rememberMe: null,
                                      });
                                    }}
                                    className="icon-size"
                                    id="ChangeUser"
                                  />
                                  <Tooltip
                                    placement="right"
                                    isOpen={this.state.tooltipOpen}
                                    target="ChangeUser"
                                    toggle={this.toggle}
                                  >
                                    Change User
                                  </Tooltip>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <label htmlFor="email">Password</label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text bg-white"
                                id="basic-addon1"
                              >
                                <RiLockPasswordLine className="icon-size" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={this.state.Password}
                              type={
                                this.state.isPasswordVisible
                                  ? "text"
                                  : "password"
                              }
                              onChange={this.OnChangepwd.bind(this)}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text bg-white eye-parent"
                                id="basic-addon1"
                              >
                                <span>
                                  <AiOutlineEye
                                    style={{
                                      color: this.state.isPasswordVisible
                                        ? "#777f80"
                                        : "",
                                    }}
                                    className="fa fa-eye icon-size"
                                    onClick={() => {
                                      this.setState({
                                        isPasswordVisible:
                                          !this.state.isPasswordVisible,
                                      });
                                    }}
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                          {localStorage.getItem("RememberMe") != "true" ? (
                            <div className="form-group form-check">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input mt-1"
                                  type="checkbox"
                                  value="Yes"
                                  onChange={(e) => {
                                    this.setState({
                                      rememberMe: e.target.checked,
                                    });
                                  }}
                                />{" "}
                                Remember me
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn Bechofy-btn btn-block waves-effect waves-light"
                            type="submit"
                            onClick={this.onClickLogin.bind(this)}
                          >
                            Log In
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};
export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
