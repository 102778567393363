import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import backicon from '../../assets/images/backicon.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
class categoryManagement extends Component {
  constructor(props) {
    super(props);
    const { action } = props.match.params;
    ////
    this.state = {
      imagePreviewUrl:
        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
      ImageApiUrl: 'https://api.bechofy.in/AddImage',
      Status: "Yes",
      sectionwithimage: "No",
      ImageData: [],
      VerticalData: [],
      HoroscopeName: "",
      HoroscopeId: null,
      LuckyColor:"",
      ColorCode:"",
      LuckyNumber:"",
      Action: action,
      HoroscopeDesciption: "",
      Verticalname: "",
      originalImage: [],
      originalLink: "",
      clicked: false,
      uploadImage: false,
      ImageName: null,
      CategoryImage: null,
      hasSubcategory: "No",
      radioDisabled: false,
      showonHomePage: "No",
      horoscopeImage: null,
    };
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticalData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );
    var det = localStorage.getItem("HoroscopeDetails");
    var HoroscopeData = JSON.parse(det);
    if (HoroscopeData != null) {
      if (this.state.Action == "update") {
        this.setState({
          radioDisabled: true,
          HoroscopeName: HoroscopeData.fld_name,
          HoroscopeId: HoroscopeData.fld_id,
          ColorCode: HoroscopeData.fld_colorcode,
          LuckyColor: HoroscopeData.fld_color,
          LuckyNumber: HoroscopeData.fld_lucky_number,
          Status: HoroscopeData.fld_showOnWebsite == "Active" ? "Yes" : "No",
        });
      }
      if (HoroscopeData.fld_image != null) {
        this.setState({
          imagePreviewUrl: HoroscopeData.fld_image,
          horoscopeImage: HoroscopeData.fld_image,
        });
      } else {
        this.setState({
          imagePreviewUrl:
            "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
        });
      }
      if (this.state.Action == "update") {
        new Promise((resolve, reject) => {
          setTimeout(resolve, 1000);
        }).then(() => {
          this.setState({
            HoroscopeDesciption: HoroscopeData.fld_long_desc,
            // TermsCondition:OfferData.fld_termscondition,
          });
        });
      }
    }
  }
  // ==Image Upload onChange Function=======
  photoUpload = async(e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    await this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name.split(".").slice(0, -1).join(".") + ".webp",
      uploadImage: true,
    });
    this.onUploadImage();
  };
  onChangeDescription(text) {
    this.setState({
      HoroscopeDesciption: text.editor.getData(),
    });
  }
  onSaveData() {
      if (this.state.HoroscopeName != "") {
        if (
          this.state.imagePreviewUrl !=
          "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
        ) {
          this.onPost();
        } else {
          Notiflix.Notify.Failure("Please upload Horoscope image");
        }
      } else {
        Notiflix.Notify.Failure("Please enter the Horoscope name");
      }
  }
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    if (this.state.horoscopeImage != this.state.imagePreviewUrl) {
      if (this.state.outputFileName != undefined) {
        PostApiCall.postRequest(
          {
            horoscopeid: this.state.HoroscopeId,
            // verticalid: this.state.Verticalname,
            name: this.state.HoroscopeName,
            shortdesc: null,
            longdesc: this.state.HoroscopeDesciption,
            icon: this.state.sectionwithimage,
            image: this.state.horoscopeImage,
            status: this.state.Status == "Yes" ? "Active" : "Inactive",
            color: this.state.LuckyColor,
            colorcode: this.state.ColorCode,
            luckynumber: this.state.LuckyNumber,
            // action: this.state.Action,
            // createdon: moment().format("YYYY-MM-DD"),
            // updatedon: moment().format("YYYY-MM-DD"),
            // updatedby: details[0].fld_userid,
            // hassubcategory: this.state.hasSubcategory,
            // featuredcat: this.state.showonHomePage,
            // sectionwithimage: this.state.sectionwithimage
          },
          "AddHoroscope"
        ).then((resultcategory) =>
          resultcategory.json().then((obj) => {
            if (resultcategory.status == 200 || resultcategory.status == 201) {
              // this.onPostAddCategoryImage(obj);
              //  //
              Notiflix.Loading.Remove();
            } else {
              Notiflix.Loading.Remove();
              Notiflix.Notify.Failure(obj.data);
            }
          })
        );
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Failure("Please Upload Horoscope Image");
      }
    } else {
      this.onUpdateCategory();
    }
  };
  onUpdateCategory = () => {
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        horoscopeid: this.state.HoroscopeId,
            // verticalid: this.state.Verticalname,
            name: this.state.HoroscopeName,
            shortdesc: null,
            longdesc: this.state.HoroscopeDesciption,
            icon: null,
            image: this.state.horoscopeImage,
            status: this.state.Status == "Yes" ? "Active" : "Inactive",
            color: this.state.LuckyColor,
            colorcode: this.state.ColorCode,
            luckynumber: this.state.LuckyNumber,
            // action: this.state.Action,
            // createdon: moment().format("YYYY-MM-DD"),
            // updatedon: moment().format("YYYY-MM-DD"),
            // updatedby: details[0].fld_userid,
            // hassubcategory: this.state.hasSubcategory,
            // featuredcat: this.state.showonHomePage,
            // sectionwithimage: this.state.sectionwithimage
      },
      "AddHoroscope"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          // this.onPostAddCategoryImage(obj);
          //  //
          Notiflix.Loading.Remove();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  // onPostAddCategoryImage(obj) {
  //   var login = localStorage.getItem("LoginDetail");
  //   var details = JSON.parse(login);
  //   if (this.state.CategoryImage != this.state.imagePreviewUrl) {
  //     PostApiCall.postRequest(
  //       {
  //         id: JSON.parse(JSON.stringify(obj.data[0])).HoroscopeId,
  //         image: 'https://store.bechofy.in/images/' +
  //           JSON.parse(localStorage.getItem("ClientDetails"))[0]
  //             .fld_dbschema +
  //           "/categoryImages/" +
  //           this.state.HoroscopeName.trim().replace(/\s/g, "-") +
  //           "-" +
  //           this.state.outputFileName.split('.').slice(0, -1).join('.') + '.webp',
  //         updatedon: moment().format("YYYY-MM-DD"),
  //         updatedby: details[0].fld_userid,
  //       },
  //       "UpdateCategoryImage"
  //     ).then((results1) =>
  //       results1.json().then((obj1) => {
  //         if (results1.status == 200 || results1.status == 201) {
  //           // //
  //           // Notiflix.Loading.Remove();
  //           Notiflix.Notify.Success(
  //             "Category successfully added. Image upload in process"
  //           );
  //           this.onUploadImage(obj);
  //         }
  //       })
  //     );
  //   } else {
  //     PostApiCall.postRequest(
  //       {
  //         id: JSON.parse(JSON.stringify(obj.data[0])).HoroscopeId,
  //         image: this.state.imagePreviewUrl,
  //         updatedon: moment().format("YYYY-MM-DD"),
  //         updatedby: details[0].fld_userid,
  //       },
  //       "UpdateCategoryImage"
  //     ).then((results1) =>
  //       results1.json().then((obj1) => {
  //         if (results1.status == 200 || results1.status == 201) {
  //           // //
  //           Notiflix.Loading.Remove();
  //           Notiflix.Notify.Success("Category successfully updated.");
  //           window.location.href = "/category-management";
  //         } else {
  //           Notiflix.Loading.Remove();
  //           Notiflix.Notify.Failure(obj1.data);
  //         }
  //       })
  //     );
  //   }
  // }
  async onUploadImage(obj) {
    Notiflix.Loading.Dots("Uploading Images...");
    //
    let response;
    if (this.state.outputFileName != undefined) {
      var options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(this.state.originalImage, options).then(
        (compressedFile) => {
          //
          //this.state.HoroscopeName.trim().replace(/\s/g, "-") +
          //"-" +
          //compressedFile.name)
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "horoscopeImages");
          form.append(
            "filename",
            this.state.HoroscopeName.trim().replace(/\s/g, "-") +
            "-" +
            compressedFile.name
          );
          response = fetch(this.state.ImageApiUrl, {
            method: "POST",
            body: form,
            headers: {
              "imgpath": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory,
              "x-auth-token": JSON.parse(localStorage.getItem("access")),
              "schema": JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_dbschema
            }
          })
            .then((response) => response.json()
              .then((data) => {
                if (response.status == 200 || response.status == 201) {
                  //
                  //
                  // this.setState({
                  // 	ImageName: data.Message.split(",")[2].split("=")[1].trim()
                  // })
                  this.setState({
                    horoscopeImage: data.image
                  })
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Success("Image successfully uploaded.");
                  // window.location.href = "/horoscope-management";
                } else {
                  Notiflix.Loading.Remove();
                  Notiflix.Notify.Failure("Image upload failed please tyr again or contact support.");
                }
              })
            );
          // //
        }
      );
    }
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  render() {
    // //
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ')}
              breadcrumbItem1={
                this.state.HoroscopeId == null
                  ? "Add New Category"
                  : "Update Category"
              }
              urlPath={"/category-management"}
              urlPath1={
                this.state.HoroscopeId == null
                  ? "/add-category/create"
                  : "/add-category/update"
              }
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          {this.state.HoroscopeId == null ? (
                            <h5 className="Bechofy-text">Add New Horoscope</h5>
                          ) : (
                            <h5 className="Bechofy-text">Update Category</h5>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <Link to={`/category-management`}>
                          <button
                            // onClick={() => {
                            //   window.location.href = "/category-management";
                            // }}
                            className="btn align-items-center Bechofy-btn AddNewBtn"
                            style={{
                              marginTop: '9px'
                            }}
                          >
                            <span className="">
                              <img src={backicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }} /> </span>
                            Back to Horoscope List{" "}
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row  ">
                            <div className="col col-12">
                              <div className="row">

                                

                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      Horoscope Name
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="HoroscopeName"
                                      className="form-control"
                                      value={this.state.HoroscopeName}
                                      onChange={(text) => {
                                        this.setState({
                                          HoroscopeName: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="LuckyColor">
                                      Lucky Color
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="LuckyColor"
                                      className="form-control"
                                      value={this.state.LuckyColor}
                                      onChange={(text) => {
                                        this.setState({
                                          LuckyColor: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="ColorCode">
                                      Color Code
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="ColorCode"
                                      className="form-control"
                                      value={this.state.ColorCode}
                                      onChange={(text) => {
                                        this.setState({
                                          ColorCode: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <label htmlFor="LuckyNumber">
                                      Lucky Number
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="LuckyNumber"
                                      className="form-control"
                                      value={this.state.LuckyNumber}
                                      onChange={(text) => {
                                        this.setState({
                                          LuckyNumber: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* show on website */}
                                <div className="col-3">
                                  <div className="form-group">
                                    <label htmlFor="HoroscopeName">
                                      Show On Website
                                      <span className="mandatory">*</span>
                                    </label>
                                    <br />
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      className="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="sw-arrows-first-name">
                                      Description(maximum 500 Characters)
                                    </label>
                                    <div className="niceeditors">
                                      <CKEditor
                                        config={{
                                          extraPlugins:
                                            "justify,font,colorbutton",
                                        }}
                                        data={this.state.HoroscopeDesciption}
                                        onChange={this.onChangeDescription.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-1"></div>
                              <div className="col-md-11">
                                <label htmlFor="sw-arrows-first-name">
                                  Upload Category Image (Size &lt; 100kb,
                                  500*500)<span className="mandatory">*</span>
                                </label>
                                <div className="div1">
                                  <ImgUpload
                                    onChange={this.photoUpload}
                                    src={this.state.imagePreviewUrl}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-3 offset-9">
                            <button
                              style={{ float: "right" }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={this.onSaveData.bind(this)}
                            >
                              {this.state.HoroscopeId == null ? (
                                <span>Save Horoscope</span>
                              ) : (
                                <span>Update Horoscope</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/*
                </Card>
                            </Col>
                               </Row> */}
                  {/* </Card> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default categoryManagement;
