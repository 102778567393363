var urlString = "https://api.bechofy.in/";

const PostApiCall = {
	postRequest(userData, url) {
		return fetch(urlString + url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
				"schema": "bechofy_db"
			},
			body: JSON.stringify(userData),
		})
			.then((response) => {
				// //
				return response;
			})
			.catch((error) => {
				//
				return error;
			});
	},
};
export default PostApiCall;
