import React, { Component } from "react";
// import { Checkbox, input, Radio, Select } from "antd";
import moment from "moment";
import Notiflix from "notiflix";
import { FaRupeeSign } from "react-icons/fa";
// import { FaLocationDot } from "react-icons/fa6";
import { Form, Nav } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import { BsCreditCardFill } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { Card, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import UpiIcon from "../../assets/images/icons/upi.png";
import GetApiCall from "../../GETAPI";
import "./CreateNewOrder.css";

class CreateNewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: [],
      customerId: null,
      name: "",
      email: "",
      isOther: "",
      title: "",
      mobile: "",
      area: "",
      flatNo: "",
      landmark: "",
      countryData: [],
      cityData: [],
      stateData: [],
      countryId: null,
      stateId: null,
      cityId: null,
      country: null,
      state: null,
      city: null,
      pincode: "",
      numRegex: /^[0-9]*$/,
      longitude: null,
      latitude: null,
      filteredCustomers: [],
      noMatching: false,
      addressId: null,
      allItems: [],
      quantity: null,
      selectedItemId: null,
      cartItems: [],
      orderId: null,
      showAddress: false,
      sameShippingAddress: false,
      showDangerText: false,
      isDisabled: false,
      paymentMode: "Cash",
      offerList: []
    };
  }

  componentDidMount() {
    this.GetCustomerList();
    this.AllItems();
    this.loadInitialAddressData();
    this.GetOfferList();
  }
  GetOfferList = () => {
    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          offerList: obj.data
        })
      })
    );
  }
  onChangeCountry = (countryEvent) => {
    this.setState({
      countryId: countryEvent.target.value,
      country: this.state.countryData[parseInt(countryEvent.target.value) + 1].label,
    });
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        countryid: countryEvent.target.value,
        Country: this.state.countryData[parseInt(countryEvent.target.value) + 1]
          .label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            stateId: obj.data[0].value,
            state: obj.data[0].label,
          });
          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  cityData: objcity.data,
                  stateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  this.setState({
                    cityId: objcity.data[0].value,
                    city: objcity.data[0].label,
                  });
                }
                Notiflix.Loading.Remove();
              }
            })
          );
        }
      })
    );
  }
  onChangeState = (state) => {
    this.setState({
      stateId: state.target.value,
    });
    Notiflix.Loading.Dots("Please wait...");
    for (var i = 0; i < Object.keys(this.state.stateData).length; i++) {
      if (this.state.stateData[i].value == state.target.value) {
        this.setState({
          state: this.state.stateData[i].label,
        });
      }
    }
    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            this.setState({
              cityData: obj.data,
              cityId: obj.data[0].value,
              city: obj.data[0].label,
            });
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  onChangeCity = (city) => {
    this.setState({
      cityId: city.target.value,
    });
    for (var i = 0; i < Object.keys(this.state.cityData).length; i++) {
      if (this.state.cityData[i].value == city.target.value) {
        this.setState({
          city: this.state.cityData[i].label,
        });
      }
    }
  }

  handleTitleChange = (title) => {
    this.setState({ title });
  };
  loadInitialAddressData = () => {
    Notiflix.Loading.Init({ svgColor: "#0f3d74" });
    navigator.geolocation.getCurrentPosition((position) => {
      const Latitude = position.coords.latitude;
      const Longitude = position.coords.longitude;
      this.setState({ longitude: Longitude, latitude: Latitude });
    });

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          countryData: obj.data,
        });
        if (obj.data.length != 0) {
          this.setState({
            countryId: "101",
            country: obj.data[152].label,
          });
        }
        PostApiCall.postRequest(
          {
            countryid: obj.data[152].value,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                this.setState({
                  stateId: objstate.data[21].value,
                  stateData: objstate.data,
                  state: objstate.data[21].label,
                });
              }
              PostApiCall.postRequest(
                {
                  stateid: objstate.data[21].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      this.setState({
                        cityId: objcity.data[0].value,
                        cityData: objcity.data,
                        city: objcity.data[0].label,
                      });
                    }
                  }
                })
              );
            }
          })
        );
      })
    );

  };


  GetCustomerList = () => {
    PostApiCall.postRequest(
      { WhereClause: "", RecordCount: "*" },
      "GetCustomerList"
    )
      .then((results) => results.json())
      .then((obj1) => {

        this.setState({ customerList: obj1.data });
      });
  };

  AllItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetItemsDetails")
      .then((results) => results.json())
      .then((obj1) => {
        this.setState({ allItems: obj1.data });
      });
  };

  handleSearch = (input) => {
    const filtered = this.state.customerList.filter((dat) =>
      dat.fld_phone_number.toLowerCase().includes(input.target.value?.toLowerCase())
    );
    this.setState({
      mobile: input.target.value,
      filteredCustomers: filtered,
      noMatching: filtered.length === 0 && input.length > 0,
    });
  };

  onChange = (e) => {
    this.setState({
      title: e.target.value,
      isOther: e.target.value === "Other" ? "Other" : "",
    });
  };


  AddToCart = (itemId) => {
    Notiflix.Loading.Dots("");
    if (this.state.customerId) {
      const login = localStorage.getItem("ClientDetails");
      const logindetails = JSON.parse(login);
      PostApiCall.postRequest(
        {
          orderdate: moment().format("lll"),
          itemdetails: `[{"Item":${itemId},"Quantity":1}]`,
          status: "INCART",
          customerid: this.state.customerId,
          createdon: moment().format("lll"),
          updatedon: moment().format("lll"),
          orderid: this.state.cartItems[0]?.fld_cart_id || 0,
          updatedby:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_userid,
        },
        "AddToCartWebsite"
      )
        .then((result) => {
          result.json()?.then((obj) => {
            if (result.status === 200 || result.status === 201) {
              this.GetCartDetails(this.state.customerId, obj?.data[0]?.order_id);
              this.setState({ orderId: obj?.data[0]?.order_id });
              Notiflix.Notify.Success("Product added to Cart.");
            } else {
              Notiflix.Notify.Failure(obj.data);
            }
            Notiflix.Loading.Remove();
          });
        })
    } else {
      Notiflix.Loading.Remove()
      Notiflix.Notify.Failure("Add a customer first", { position: 'center-bottom' })
    }
  };

  GetCartDetails = (customerid, orderid) => {
    PostApiCall.postRequest(
      { orderid: this.state.orderId || orderid, id: customerid },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          this.setState({
            cartItems: res1.data,
            orderId: res1.data[0]?.fld_cart_id,
          });
          Notiflix.Loading.Remove();
        }
      });
  };

  AddShippingCharges = () => {
    PostApiCall.postRequest(
      {
        customerid: this.state.customerId,
        orderid: this.state.orderId,
        addressid: this.state.addressId,
      },
      "Add_shipping_chrg"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          results.status === 200 ||
          results.status === 201
        ) {
          this.GetCartDetails(this.state.customerId);
        }
      })
    );
  }
  ApplyOffer = (offerid) => {
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        orderid: this.state.orderId,
        customerid: this.state.customerId,
        status: offerid == 0 ? 'remove' : 'apply',
        stateid: 0,
        couponid: offerid
      },
      "ApplyOffer"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status === 200 || result.status === 201) {
          this.GetCartDetails(this.state.customerId, this.state.orderId);
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Discount applied sucessfully");
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj3.data);
        }
      })
    );
  };

  onPostRegistration = () => {
    if (!this.state.mobile) {
      Notiflix.Notify.Failure("Invalid customer's mobile number", { position: "center-bottom" })
    } else {
      try {
        Notiflix.Loading.Circle();
        PostApiCall.postRequest(
          {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            status: "Active",
            createdon: new Date(),
          },
          "AddGuestCustomer"
        )
          .then((result) => {
            result.json()?.then((obj) => {
              if (result.status === 200 || result.status === 201) {
                this.setState({ customerId: obj.data[0].CustomerId });
                PostApiCall.postRequest(
                  {
                    CUSTOMERID: obj.data[0].CustomerId,
                    addressid: null,
                    title: this.state.title,
                    name: this.state.name,
                    mobileNo: this.state.mobile,
                    area: this.state.area,
                    flatno: this.state.flatNo,
                    landmark: this.state.landmark,
                    country: this.state.countryId,
                    state: this.state.stateId,
                    city: this.state.cityId,
                    pincode: this.state.pincode,
                    action: "INSERT",
                    longitude: this.state.longitude,
                    latitude: this.state.latitude,
                  },
                  "AddCustomerAddress"
                )
                  .then((result) => result.json())
                  .then((obj) => {
                    if (result.status === 200 || result.status === 201) {
                      this.GetCustomerList();
                      this.setState({
                        addressId: obj.data[0]?.id
                      })
                      Notiflix.Notify.Success("Address Successfully Added");
                    } else {
                      Notiflix.Notify.Failure(obj.data);
                    }
                    Notiflix.Loading.Remove();
                  });
                localStorage.setItem("CustomerData", JSON.stringify(obj.data));
              } else {
                Notiflix.Notify.Failure(obj.data);
                Notiflix.Loading.Remove();
              }
            })
          });
      } catch (error) {
        Notiflix.Notify.Failure("Something went wrong")
      }
    }
  };

  MoveCartOrder = () => {
    console.log(this.state.addressId)
    Notiflix.Loading.Circle();
    if (this.state.customerId) {
      PostApiCall.postRequest(
        {
          cartid: this.state.orderId,
          customerid: this.state.customerId,
          paymentstaus: 'Successful',
          paymentmode: this.state.paymentMode,
          paymenttrxno: this.state.cartItems[0]?.fld_payment_trx_num,
          paymentgateway: this.state.paymentMode == "Cash" ? "COD" : "NA",
          billingaddress: this.state.addressId,
          shippingaddress: this.state.addressId,
          orderstatus: 'InProcess',
        },
        "MoveCartToOrder"
      ).then((result) =>
        result.json().then((obj3) => {
          if (result.status === 200 || result.status === 201) {
            Notiflix.Loading.Circle();
            this.SendMailers(obj3.data[0]?.order_id) // get the item amount and send it to the mailer
            const formattedDtlId = obj3.data[0].order_detail_id.includes(",") ? obj3.data[0].order_detail_id : parseInt(obj3.data[0].order_detail_id, 10);
            this.HandleDispatch(formattedDtlId, obj3.data[0]?.order_id)
            PostApiCall.postRequest(
              {
                cartID: this.state.cartItems[0]?.fld_cart_id
              },
              "PurgeCart"
            ).then((result) => result.json().then((obj3) => {
              if (result.status == 200 || result.status == 201) {
              } else {
                Notiflix.Notify.Failure(
                  "Not able to process data"
                );
              }
            })
            );
            this.GetCartDetails(this.state.customerId, this.state.orderId);
          } else {
            Notiflix.Notify.Failure(obj3.data);
          }
        })
      );
    } else {
      Notiflix.Loading.Remove()
      Notiflix.Notify.Failure("Add a customer first", { position: "center-bottom" })
    }
  };

  SendMailers = (orderid) => {
    PostApiCall.postRequest(
      {
        ordernumber: this.state.cartItems[0]?.fld_payment_trx_num,
        customername: this.state.name,
        customeremail: this.state.email,
        amount: this.state.cartItems[0]?.fld_cart_amount,
        mobile: this.state.mobile,
        orderid: orderid,
      },
      "SendMailer"
    )
      .then((result) => result.json()
        .then((obj) => {
          if (result.status === 200 || result.status === 201) {
            Notiflix.Notify.Success("Order placed successfully.");
          } else {
            Notiflix.Notify.Failure(obj.data);
          }
          Notiflix.Loading.Remove();
        }));
  };

  HandleDispatch = (dtlId, orderId) => {
    let details = JSON.parse(localStorage.getItem("LoginDetail"));
    PostApiCall.postRequest(
      {
        dispatchid: null,
        orderid: orderId,
        disptachdate: null,
        dispatchmode: "",
        status: 'Dispatched',
        trackingno: null,//from
        shippingdate: null,
        deliveryagent: null,
        itemlist: dtlId,
        warehouseid: 1, // for kawwaii specific
        createdBy: details[0].fld_userid,
        updatedby: details[0].fld_userid,
      },
      "DispatchOrder"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success('Item has been dispatched Successfully.')
        } else {
          Notiflix.Loading.Remove()
          Notiflix.Notify.Failure(obj.data)
        }
      })
    );
  }
  OnMobileChange = (mobile) => {
    const mobileLower = mobile?.toLowerCase();
    const selectedData = this.state.customerList.find((dat) =>
      dat.fld_phone_number.toLowerCase().includes(mobileLower)
    );
    console.log(selectedData)
    const stateUpdate = {
      showAddress: !!selectedData,
      title: selectedData?.fld_address_type || "",
      name: selectedData?.fld_name || "",
      email: selectedData?.fld_email_address || "",
      flatNo: selectedData?.fld_address_1 || "",
      area: selectedData?.fld_address_2 || "",
      landmark: selectedData?.fld_landmark || "",
      countryId: selectedData?.fld_country || "101",
      stateId: selectedData?.fld_state || "4008",
      cityId: selectedData?.fld_city || "147707",
      country: selectedData?.CountryName || null,
      state: selectedData?.StateName || null,
      city: selectedData?.CityName || null,
      pincode: selectedData?.fld_pincode || "400053",
      customerId: selectedData?.fld_customerid || null,
      addressId: selectedData?.fld_address_id || null,
      showDangerText: !selectedData,
      isDisabled: !!selectedData,
    };

    this.setState(stateUpdate, () => {
      if (selectedData) {
        this.GetCartDetails(selectedData.fld_customerid);
      }
    });
  }

  DeleteCart = (cartlineid) => {
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        cartID: this.state.orderId,
        customerid: this.state.customerId,
        cartlineid: cartlineid,
        stateid: 0,
      },
      "DeleteCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Notify.Success("Product is successfully deleted.");
          this.GetCartDetails(this.state.customerId, this.state.orderId);
        } else {
          Notiflix.Notify.failure(obj3.data);
        }
      })
    );
  };
  handleTabSelect = (key) => {
    this.setState({
      paymentMode: key
    })

  };

  render() {
    const {
      filteredCustomers,
      noMatching,
      title,
      isOther,
      name,
      email,
      flatNo,
      area,
      landmark,
      countryId,
      stateId,
      cityId,
      pincode,
      sameShippingAddress,
      showAddress,
      cartItems,
      allItems,
      quantity,
      numRegex,
    } = this.state;

    return (
      <div className="page-content">
        <Container fluid className="mb-4">
          <Row>
            <Col xl="12">
              <Card className="pagebackground my-4 px-3 py-2 bg-white">
                <div className="row">
                  <div className="col-12">
                    <h1 className="mb-2">Create New Order</h1>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Mobile No.</label>
                      <input
                        type="number"
                        placeholder="* Enter Customer's Mobile Number"
                        className="form-control"
                        onBlur={(e) => {
                          if (this.state.mobile.length > 9) {
                            this.OnMobileChange(e.target.value);
                          }
                        }}
                        onChange={(e) =>
                          this.setState({ mobile: e.target.value })
                        }
                        value={this.state.mobile} />
                      <div className="city-search">Check availability</div>
                      {!this.state.mobile.length &&
                        <p className="text-danger mt-2">
                          Enter contact number to search
                        </p>
                      }
                      {this.state.showDangerText &&
                        <p className="text-danger mt-2">
                          Sorry, no customer available with this contact number, enter details to add new customer.
                        </p>
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    <h5>Customer Details</h5>
                    <div className="input-box">
                      <div className="select-add-type-box">
                        <span
                          type="button"

                          className={` ${this.state.title === "Home"
                            ? "select-add-type-btn active"
                            : "select-add-type-btn"
                            }`}
                          onClick={() => this.handleTitleChange("Home")}>
                          Home
                        </span>
                        <span
                          type="button"
                          className={` ${this.state.title === "Office"
                            ? "select-add-type-btn active"
                            : "select-add-type-btn"
                            }`}
                          onClick={() => this.handleTitleChange("Office")}>
                          Work
                        </span>
                        <span
                          type="button"
                          className={` ${this.state.title === "Other"
                            ? "select-add-type-btn active"
                            : "select-add-type-btn"
                            }`}
                          onClick={() => this.handleTitleChange("Other")}>
                          Others
                        </span>
                      </div>
                    </div>
                  </div>
                  {isOther === "Other" && (
                    <div className="col-lg-4">
                      <div className="input-box">
                        <label>
                          <input
                            className="form-control"
                            size="large"
                            placeholder="Save As"
                            value={this.state.title}
                            onChange={(text) =>
                              this.setState({ title: text.target.value })
                            }
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Name</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Name"
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Email</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Flat/House/Company/Apartment No.</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Flat/House/Company/Apartment No."
                        value={this.state.flatNo}
                        onChange={(e) =>
                          this.setState({ flatNo: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Area/Colony/Street/Sector/Village</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Area/Colony/Street/Sector/Village"
                        value={this.state.area}
                        onChange={(e) =>
                          this.setState({ area: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Landmark</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Landmark"
                        value={this.state.landmark}
                        onChange={(e) =>
                          this.setState({ landmark: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Country</label>
                      <select
                        disabled={this.state.isDisabled}
                        className="form-control w-100"
                        style={{ width: 120 }}
                        value={this.state.countryId}
                        onChange={this.onChangeCountry}>
                        {this.state.countryData?.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>State</label>
                      <select
                        disabled={this.state.isDisabled}
                        className="form-control w-100"
                        style={{ width: 120 }}
                        value={this.state.stateId}
                        onChange={this.onChangeState}>
                        {this.state.stateData?.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>City</label>
                      <select
                        disabled={this.state.isDisabled}
                        className="form-control w-100"
                        style={{ width: 120 }}
                        value={this.state.cityId}
                        onChange={this.onChangeCity}>
                        {this.state.cityData?.map((schedule) => (
                          <option key={schedule.label} value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-box">
                      <label>Pincode</label>
                      <input
                        disabled={this.state.isDisabled}
                        size="large"
                        className="form-control"
                        placeholder="Enter Pincode"
                        value={this.state.pincode}
                        onChange={(e) => {
                          if (
                            numRegex.test(e.target.value) &&
                            e.target.value.length <= 6
                          ) {
                            this.setState({ pincode: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {!this.state.customerId && this.state.customerId != "" && (
                    <div className="col-12 my-3">
                      <button
                        type="button"
                        className="theme-btn"
                        onClick={this.onPostRegistration}>
                        Save
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  {showAddress && flatNo && area && (
                    <div className="col-lg-3">
                      <div className="biilingadd-box">
                        <label>Billing Address</label>
                        <p>
                          {flatNo}, <br /> {area}, <br /> {this.state.state} -{" "}
                          {pincode}, {this.state.country}.
                        </p>
                      </div>
                      {/* <p className="mt-3">
                        <input
                          type="checkbox"
                          className="mr-2"
                          onChange={() =>
                            this.setState({
                              sameShippingAddress:
                                !this.state.sameShippingAddress,
                            })
                          }
                          checked={this.state.sameShippingAddress}
                        />
                        <span>Shipping address is same as billing address</span>
                      </p> */}
                      {/* {this.state.sameShippingAddress && (
                        <div className="biilingadd-box">
                          <label>Shipping Address</label>
                          <p>
                            {flatNo}, <br /> {area}, <br /> {this.state.state} -{" "}
                            {pincode}, {this.state.country}.
                          </p>
                        </div>
                      )} */}
                    </div>
                  )}
                  <div className="col-12">
                    <h5>Cart</h5>
                  </div>
                  {allItems
                    .filter((item) => item.fld_status === "Active")
                    ?.map((filterdItem) => (
                      <div
                        key={filterdItem.fld_variantid}
                        className="col-lg-3 mb-4">
                        <div className="product-card">
                          <div className="image">
                            <img
                              src={filterdItem.VariantImage}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="content">
                            <div>
                              <p className="name">
                                {filterdItem.fld_variantname}
                              </p>
                              <span className="price">
                                <FaRupeeSign />
                                {filterdItem.fld_selling_price}
                              </span>
                            </div>
                            <div className="add-cart">
                              <div
                                className="add-btn cursor-pointer"
                                onClick={() => { this.AddToCart(filterdItem.fld_variantid) }}>
                                Add to Cart
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-12 mt-4 item-list-section">
                    <h5>Items in Cart</h5>
                    <div className="row">
                      {this.state.cartItems.length > 0
                        ? this.state.cartItems?.map((data) => (
                          <div className="col-lg-3">
                            <div key={data.fld_cart_dtl_id} className="item-card">
                              <div className="image">
                                <img
                                  src={data.VariantImage}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <div>
                                  <p className="name">{data.fld_variantname}</p>
                                  <p className="quantity">
                                    <span>Quantity: </span>
                                    {data.fld_quantity}
                                  </p>
                                </div>
                                <div>
                                  <p className="price">
                                    <FaRupeeSign />
                                    {data.Basevalue}
                                  </p>
                                  <span
                                    className="remove-btn"
                                    onClick={() =>
                                      this.DeleteCart(data.fld_cart_dtl_id)
                                    }>
                                    Remove
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : <div className="col-12">No Items available in Cart</div>}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-box">
                      <label className="my-2">Discount %</label>
                      <Form.Select className="form-control" disabled={this.state.customerId === null ? true : false} aria-label="Default select example" onChange={(e) => this.ApplyOffer(e.target.value)}>
                        <option>Open this select menu</option>
                        <option key={1} value={0}>0% OFF</option>
                        {this.state.offerList?.map((data) => {
                          return (
                            <option
                              key={`offer${data.fld_offerid}`}
                              value={data.fld_offerid}>{data.fld_name}</option>
                          )
                        })}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-box payment-mod-btns">
                      <h5>Select Payment Mode</h5>

                      <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={this.handleTabSelect}>
                        <Nav variant="pills" className="mt-2">
                          <Nav.Item>
                            <Nav.Link eventKey="Cash"><GiTakeMyMoney /> COD</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Credit Card / Debit Card"><BsCreditCardFill /> Credit Card / Debit Card</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="UPI"><img src={UpiIcon} className="img-fluid upi-icon" /> UPI</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Tab.Container>

                    </div>
                  </div>
                  {/* {this.state.customerId !== null ?
                    <div className="col-12">
                      <div class="form-check" onClick={this.AddShippingCharges}>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Add Shipping Charges
                        </label>
                      </div>
                    </div>
                    : ""} */}
                  <div className="col-12 mt-4">
                    <div className="total-price-card">
                      <h5>Bill Details</h5>
                      <div className="card-box">
                        <span>Item Total</span>
                        <p>
                          <FaRupeeSign />
                          {this.state.cartItems[0]?.total_mrp}
                        </p>
                      </div>
                      {this.state.customerId !== null && (this.state.cartItems[0]?.fld_cart_discount_amount ||
                        this.state.cartItems[0]?.fld_cart_discount_amount !== 0) && (
                          <div className="card-box">
                            <span>Offer Discount</span>
                            <p>
                              <FaRupeeSign />
                              {this.state.cartItems[0]?.fld_cart_discount_amount}
                            </p>
                          </div>
                        )}
                      {this.state.cartItems[0]?.fld_coupon_discount !== 0 && this.state.customerId !== null && (
                        <div className="card-box">
                          <span>Coupon Discount - ( {this.state.cartItems[0]?.fld_code} )</span>
                          <p>
                            <FaRupeeSign />
                            {this.state.cartItems[0]?.fld_coupon_discount}
                          </p>
                        </div>
                      )}
                      <div className="card-box">
                        <span>Delivery Charges</span>
                        {this.state.cartItems[0]?.fld_shipping_charge === null ||
                          this.state.cartItems[0]?.fld_shipping_charge === 0 ? (
                          "Free"
                        ) : (
                          <p>
                            <FaRupeeSign />
                            {this.state.cartItems[0]?.fld_shipping_charge}
                          </p>
                        )}
                      </div>
                      <div className="card-box">
                        <span>GST ( Inclusive )</span>
                        <p>
                          <FaRupeeSign />
                          {this.state.cartItems[0]?.fld_total_gst}
                        </p>
                      </div>
                      <div className="card-box total-price">
                        <span>Grand Total</span>
                        <p>
                          <FaRupeeSign />
                          {this.state.cartItems[0]?.fld_cart_amount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex my-3 justify-content-center">
                  <button
                    type="button"
                    className="theme-btn me-3"
                    onClick={this.MoveCartOrder}>
                    Save Order
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CreateNewOrder;
