import Parser from "html-react-parser";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { ToWords } from 'to-words';
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const inr = require('inr-words');
var vend = [];
var selPro = []
class Dispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderData: [],
      SelectedProduct: [],
      ShippingDate: null,
      TrackingNumber: null,
      DispatchDate: null,
      DispatchId: null,
      StateData: [],
      StockState: '',
      OrderState: '',
      WarehouseData: [],
      Warehouse: '',
      OrderId: null,
      AmountInWords: null,
      Amount: null,
      Payment_Mode: null,
      Email: null,
      OrderNumber: null,
      ClientName: "",
      ClientLogo: "",
      ClientAddress: "",
      ClientCity: "",
      ClientPhoneNumber: "",
      ClientCountry: "",
      ClinetPincode: "",
      ClientState: "",
      ClientStateId: "",
      ClientWebsite: "",
      ClientEmail: "",
      ShippingCharge: "",
      ClientGST: "",
      ShippingChargeGst: "",
      ClientPAN: ""
    };
  }
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    if (localStorage.getItem("ClientDetails") == null) {
      Notiflix.Notify.Failure("Session timed out. Please access the page from main business domain again.")
    } else {
      var clientDetails = localStorage.getItem("ClientDetails");
      var clientInformation = JSON.parse(clientDetails)
      this.setState({
        ClientName: clientInformation[0].fld_companyname,
        ClientLogo: clientInformation[0].fld_storelogo,
        ClientPhoneNumber: clientInformation[0].fld_mobilenumber,
        ClientAddress: clientInformation[0].fld_address,
        ClientCity: clientInformation[0].fld_city,
        ClientCountry: clientInformation[0].fld_country,
        ClientEmail: clientInformation[0].fld_email,
        ClinetPincode: clientInformation[0].fld_pincode,
        ClientState: clientInformation[0].fld_state,
        ClientStateId: clientInformation[0].stateid,
        ClientWebsite: clientInformation[0].fld_website,
        ClientGST: clientInformation[0].fld_gst,
        ClientPAN: clientInformation[0].fld_pan,
      })
    }
    var customer = localStorage.getItem('Dispatchdetails');
    var CustomerDetails = JSON.parse(customer)
    this.setState({
      OrderId: CustomerDetails
    })
    //
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        orderid: CustomerDetails
      },
      "Get_Dispatch_item_details"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            OrderData: obj.data,
            Amount: obj.data[0].Total_Inclusive_Tax,
            Payment_Mode: obj.data[0].Payment_Mode,
            Email: obj.data[0].fld_email,
            OrderNumber: obj.data[0].fld_orderNumber,
            ShippingCharge: obj.data[0].Shipping_Charg == null ? 0.00 : obj.data[0].Shipping_Charg,
            ShippingChargeGst: obj.data[0].Shipping_Charg_gst == null ? 0.00 : obj.data[0].Shipping_Charg_gst,
            OrderState: obj.data[0].fld_shippingstate,
          });
          this.state.OrderData.map((data, i) => {
            if (i === 0) {
              let words = this.toWords.convert(Math.round(data.Total_Inclusive_Tax));
              let awords = this.capitalizeWords(words)
              this.setState({
                AmountInWords: "Rupees " + awords + " Only",
              });
            }
          })
          Notiflix.Loading.Remove();
        }
      }))
    PostApiCall.postRequest(
      {
        orderId: CustomerDetails,
        recordCount: 'fld_name as label,fld_warehouseid as value'
      },
      "GetWarehouseForDispatch"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {

            this.setState({
              WarehouseData: obj.data,
            });
          }
        }
      }))
    Notiflix.Loading.Remove();
  }
  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false
    }
  });
  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var item = ''
    for (var i = 0; i < this.state.SelectedProduct.length; i++) {
      if (i == 0) {
        item = this.state.SelectedProduct[i].fld_order_dtl_id
      } else {
        item =
          item + ", " + this.state.SelectedProduct[i].fld_order_dtl_id
      }
    }
    let details = JSON.parse(localStorage.getItem("LoginDetail"));
    PostApiCall.postRequest(
      {
        dispatchid: this.state.DispatchId,
        orderid: JSON.parse(localStorage.getItem("Dispatchdetails")),
        disptachdate: null,
        dispatchmode: this.state.DispatchData,
        status: 'Dispatched',
        trackingno: this.state.TrackingNumber,
        shippingdate: null,
        deliveryagent: null,
        itemlist: item,
        warehouseid: parseInt(this.state.Warehouse),
        createdBy: details[0].fld_userid,
        updatedby: details[0].fld_userid,
      },
      "DispatchOrder"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success('Item has been dispatched Successfully.')
          // window.location.reload()
        } else {
          Notiflix.Loading.Remove()
          Notiflix.Notify.Failure(obj.data)
        }
      })
    );
  }
  sendMailer = () => {
    var item = ''
    for (var i = 0; i < this.state.SelectedProduct.length; i++) {
      if (i == 0) {
        item = this.state.SelectedProduct[i].fld_order_dtl_id
      } else {
        item =
          item + ", " + this.state.SelectedProduct[i].fld_order_dtl_id
      }
    }
    PostApiCall.postRequest({
      orderid: this.state.OrderNumber,
      disptachdate: moment(this.state.DispatchDate).format("DD/MM/YYYY"),
      trackingno: this.state.TrackingNumber,
      shippingdate: moment(this.state.ShippingDate).format("DD/MM/YYYY"),
      total_amount: this.state.Amount,
      payment_mode: this.state.Payment_Mode == "COD" ? "Cash On Delivery" : this.state.Payment_Mode,
      email: this.state.Email,
    }, "Mailer_Dispatch").then((result) => {
      Notiflix.Loading.Remove();
      Notiflix.Notify.Success('Mail has been successfully sent to the customer with tracking details.')
      window.location.reload()
    }
    )
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }; render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Breadcrumbs breadcrumbItem={this.capitalizeWords(window.location.pathname.replace('/', '')).replace('-', ' ').replace('_', ' ').replace('_', ' ')} urlPath={"/view-dispatch"} />
            <Row>
              <Col xl="12">
                <div id="orderform" className="dispatch-size">
                  <Card className="overflow-hidden">
                    <div className="globalTrendzEcomm-bg-soft-pink">
                      <Row>
                        <Col xs="6">
                          <div className="globalTrendzEcomm-text  p-3">
                            <h5 className="globalTrendzEcomm-text">Dispatch</h5>
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="form-group my-1 mr-sm-2">
                            <select className="form-control"
                              value={this.state.Warehouse}
                              onChange={(text) => {
                                this.setState({
                                  Warehouse: text.target.value
                                })
                                PostApiCall.postRequest(
                                  {
                                    orderid: this.state.OrderId,
                                    warehouseId: text.target.value
                                  },
                                  "Get_Dispatch_item_details"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    if (results.status == 200 || results.status == 201) {
                                      this.setState({
                                        OrderData: obj.data,
                                      });
                                      this.state.OrderData.map((data, i) => {
                                        if (i === 0) {
                                          const toWords = new ToWords();
                                          let words = toWords.convert(data?.Total_Inclusive_Tax);
                                          this.setState({
                                            AmountInWords: words,
                                          });
                                        }
                                      })
                                      Notiflix.Loading.Remove();
                                    }
                                  }))
                              }}
                            >
                              <option value="">Select Warehouse to Dispatch</option>
                              {this.state.WarehouseData.map((data =>
                                <option key={data.value} value={data.value}>{data.label}</option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <table
                      style={{
                        width: '100%', textAlign: 'center', marginLeft: 'auto',
                        marginRight: 'auto', bottom: '0px', borderRightColor: '#000',
                        marginTop: '30px',
                        borderCollapse: 'collapse',
                        border: '2px solid #000'
                      }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr style={{ borderBottom: '2px solid #000' }}>
                          <td style={{ width: '20%', verticalAlign: 'middle', borderRight: '2px solid #000', padding: '4px' }}><img src={`${this.state.ClientLogo}`} alt="logoorderform" style={{ width: '100%', marginRight: 'auto', marginLeft: 'auto', verticalAlign: "middle" }} /> </td>
                          <td colSpan="8" style={{ width: '80%' }}>
                            <h2 style={{ textAlign: 'right', fontSize: '20px', padding: '1% 1% 0 0', color: '#000', fontWeight: '600', marginBottom: '-6px' }}>Order Form</h2>
                            <h2 style={{ textAlign: 'left', fontSize: '25px', fontWeight: 'bold', padding: '0 0 0% 1%', color: '#000', margin: '0' }}>
                              {this.state.ClientName}</h2><p style={{ textAlign: 'left', padding: '0 0 0% 1%', color: '#000', fontSize: '14px', fontWeight: '600', margin: '0 0 8px' }}>{this.state.ClientAddress}<br />{this.state.ClientCity},  {this.state.ClientState} - {this.state.ClinetPincode}, {" "}
                              {this.state.ClientCountry}<br />Phone : {this.state.ClientPhoneNumber}<span style={{ padding: "1%" }}>|</span>Email : {this.state.ClientEmail}
                              <br />GSTIN : {this.state.ClientGST} <span style={{ padding: "1%" }}>|</span>PAN : {this.state.ClientPAN}</p>
                          </td>
                        </tr>
                        {this.state.OrderData.map((data, i) => {
                          if (i === 0) {
                            return (
                              <>
                                <tr style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                                  <td style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '3px', paddingBottom: '3px', borderRight: '2px solid #000' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', color: '#000' }}>Order Date</span></td>
                                  <td colSpan="5" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '3px', paddingBottom: '3px', color: '#000', fontWeight: '500', borderRight: '2px solid #000' }}>
                                    {data.fld_order_date}</td>
                                  <td style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '3px', paddingBottom: '3px', borderRight: '2px solid #000' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', color: '#000' }}>Order #</span></td>
                                  <td style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '3px', paddingBottom: '3px', color: '#000', fontWeight: '500' }}>
                                    {data.fld_orderNumber}
                                  </td>
                                </tr>
                              </>
                            )
                          }
                        })}
                        <tr className="success" style={{ borderBottom: '2px solid #000' }}>
                          <td colSpan="6" style={{ padding: '3px', fontWeight: '700', fontSize: '17px', textAlign: 'center', color: '#000', width: '50%', borderRight: '2px solid #000' }}>Billing
                            Address</td>
                          <td colSpan="6" style={{ padding: '3px', color: '#000', fontWeight: '700', fontSize: '17px', textAlign: 'center', width: '50%' }}>
                            Delivery Address</td>
                        </tr>
                        {this.state.OrderData.map((data, i) => {
                          if (i === 0) {
                            return (
                              <tr>
                                <td colSpan="6" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '2px', color: '#000', fontSize: '14px', fontWeight: '600', borderRight: '2px solid #000' }}>
                                  <p className="billing-pdf" style={{ padding: '0', margin: '0' }}>
                                    {Parser(
                                      ("<p>" + data.fld_billing_address_all + "</p>")
                                        .replace(/font-family/g, "")
                                        .replace(/<br\/?>/gi, " ")
                                    )}
                                  </p>
                                </td>
                                <td colSpan="6" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '2px', color: '#000', fontSize: '14px', fontWeight: '600' }}>
                                  <p className="billing-pdf" style={{ padding: '0', margin: '0' }}>
                                    {Parser(
                                      ("<p>" + data.fld_shipping_address_all + "</p>")
                                        .replace(/font-family/g, "")
                                        .replace(/<br\/?>/gi, " ")
                                    )}
                                  </p>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: '100%',
                        textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', borderRightColor:
                          '#000', borderTop: 'hidden', borderBottom: '2px solid #000', borderLeft: '2px solid #000', borderRight: '2px solid #000'
                      }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr className="success" style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000', width: '6%' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>SI. #</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000', width: '36%' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Item Description</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000', width: '90px' }}><span style={{ fontWeight: 'bold', fontSize: '14px', }}>Unit Price</span></td>
                          <td style={{ padding: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000', width: '90px' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Qty</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000' }}><span style={{ fontWeight: 'bold', fontSize: '14px', width: '120px' }}>Net Amount</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Tax</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Amount</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Available Stock</span></td>
                          <td style={{ paddingTop: '4px', paddingBottom: '4px', textAlign: 'center', color: '#000', borderRight: '2px solid #000' }}><span style={{ fontWeight: 'bold', fontSize: '14px' }}>Dispatch</span></td>
                        </tr>
                        {this.state.OrderData.map((data, i) => (
                          <tr style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                            <td style={{ color: '#000', borderRight: '2px solid #000', paddingTop: '7px', verticalAlign: 'top', }}>{i + 1}</td>
                            <td style={{ textAlign: 'left', borderRight: '2px solid #000', padding: '1%', color: '#000', lineHeight: '20px' }}> <span style={{ color: '#000', fontWeight: '800' }}>{data.item_name} {" "} {data.item_name == data.variant_name && "-" + data.variant_name}</span><br />
                              {data.fld_ord_info.split(",")[0] != "" && (data.fld_ord_info.split(",").length <= 0 ?
                                <div style={{ color: '#000', fontWeight: '600' }}>
                                  {data.fld_ord_info.split("#")[0]}-{data.fld_ord_info.split("#")[1]}<br />
                                </div>
                                : data.fld_ord_info.split(",").map((info) => {
                                  return (
                                    <div style={{ color: '#000', fontWeight: '600' }}>
                                      {info.split("#")[0]} - {info.split("#")[1]}<br />
                                    </div>
                                  )
                                })
                              )}
                              <span style={{ color: '#000', fontWeight: '600' }}>(HSN Code - {data.fld_hsncode})</span>
                            </td>
                            <td style={{ textAlign: 'center', color: '#000', borderRight: '2px solid #000', verticalAlign: 'top', paddingTop: '10px', fontWeight: '600', lineHeight: '20px' }}>
                              {

                                parseFloat(data.fld_mrp).toFixed(2) === parseFloat(data.fld_selling_price).toFixed(2) ?
                                  <div>{parseFloat(data.fld_mrp).toFixed(2)}</div> :
                                  <><div><strike>{parseFloat(data.fld_selling_price).toFixed(2)}</strike></div><div>{parseFloat(data.fld_mrp).toFixed(2)}</div></>
                              }
                            </td>
                            <td style={{ textAlign: 'center', color: '#000', borderRight: '2px solid #000', verticalAlign: 'top', paddingTop: '10px', fontWeight: '600', lineHeight: '20px' }}>{data.fld_quantity}</td>
                            <td style={{ textAlign: 'right', color: '#000', borderRight: '2px solid #000', verticalAlign: 'top', paddingTop: '10px', padding: '1%', width: '100px', fontWeight: '600', lineHeight: '20px' }}>{parseFloat(parseFloat(data.fld_netvalue).toFixed(2) * parseFloat(data.fld_quantity).toFixed(2)).toFixed(2)}</td>
                            <td style={{ textAlign: 'right', color: '#000', padding: '1%', borderRight: '2px solid #000', verticalAlign: 'top', paddingTop: '10px', width: '100px', fontWeight: '600', lineHeight: '20px' }}>{parseFloat(data.fld_GSTamount * data.fld_quantity).toFixed(2)}<br /><span style={{ fontSize: '13px' }}> @ {data.fld_GSTRate}%</span></td>
                            <td style={{ textAlign: 'right', color: '#000', verticalAlign: 'top', paddingTop: '10px', paddingRight: '1%', width: '100px', fontWeight: '600', lineHeight: '20px', borderRight: '2px solid #000' }}>{parseFloat(data.fld_item_value).toFixed(2)}</td>
                            <td style={{ textAlign: 'right', color: '#000', verticalAlign: 'top', paddingTop: '10px', paddingRight: '1%', width: '100px', fontWeight: '600', lineHeight: '20px', borderRight: '2px solid #000' }}>{data.stock_available}</td>
                            <td style={{ textAlign: 'right', color: '#000', verticalAlign: 'top', paddingTop: '10px', paddingRight: '1%', width: '100px', fontWeight: '600', lineHeight: '20px', borderRight: '2px solid #000' }}>


                              {data.fld_line_status == 'InCart'
                                ?
                                data.stock_available != '0' ?
                                  <input type="checkbox"
                                    checked={this.state.SelectedProduct.find(function (value) { return (value.fld_order_itemid == data.fld_order_itemid) })}
                                    onChange={() => {
                                      var cd = [...this.state.SelectedProduct]
                                      var t = 0
                                      for (var i = 0; i < cd.length; i++) {
                                        if (cd[i].fld_order_itemid == data.fld_order_itemid) {
                                          t = 1
                                        }
                                      }
                                      if (t == 0) {
                                        selPro.push(data)
                                        this.setState({
                                          SelectedProduct: selPro
                                        })
                                      } else {
                                        for (var i = 0; i < cd.length; i++) {
                                          if (cd[i].fld_order_itemid == data.fld_order_itemid) {
                                            selPro.splice(i, 1)
                                            this.setState({
                                              SelectedProduct: selPro
                                            })
                                          }
                                        }
                                      }
                                    }}
                                  />
                                  :
                                  'No Stock Available'
                                :
                                (data.fld_line_status)
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table style={{
                      width: '100%',
                      textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', borderRightColor:
                        '#000', borderTop: 'hidden', borderRight: '2px solid #000', borderLeft: '2px solid #000', borderBottom: '2px solid #000'
                    }} cellSpacing="0" cellPadding="0">
                      {this.state.OrderData.map((data, i) => {
                        if (i === 0) {
                          return (
                            <tbody>
                              <tr style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                                <th style={{ backgroundColor: '#aeaeaec2', paddingTop: '4px', paddingBottom: '4px', fontWeight: '800', width: '290px', color: '#000', borderRight: '2px solid #000' }}>Amount Chargeable in Words</th>
                                <th style={{ paddingTop: '4px', borderRight: '2px solid #000', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', fontWeight: '800', color: '#000', borderRight: '2px solid #000', width: '331px' }}>Item Total(Inclusive GST)</th>
                                <th style={{ paddingTop: '4px', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', fontWeight: '600', color: '#000' }}>{parseFloat(data.fld_base_total_with_tax).toFixed(2)}</th>
                              </tr>
                              <tr style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                                <td rowSpan="2" style={{ paddingLeft: '4px', textAlign: 'center', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000' }}>
                                  {this.state.AmountInWords}
                                </td>

                                {/* <td style={{ paddingRight: '2%', paddingTop: '4px', paddingBottom: '4px', textAlign: 'right', fontWeight: '800', color: '#000', borderRight: '2px solid #000', borderBottom: '2px solid #000' }}>Delivery Cost {data.Shipping_Charg !== 0 ? <span style={{ fontSize: '12px', fontWeight: '600' }}>(Rs. {parseFloat(data.fld_shipping_base).toFixed(2)} + 18% TAX)</span> : ""}</td> */}
                                <td style={{ paddingRight: '2%', paddingTop: '4px', paddingBottom: '4px', textAlign: 'right', fontWeight: '800', color: '#000', borderRight: '2px solid #000', borderBottom: '2px solid #000' }}>Delivery Cost</td>
                                <td style={{ paddingRight: '2%', paddingTop: '4px', paddingBottom: '4px', textAlign: 'right', color: '#000', fontWeight: '600', borderBottom: '2px solid #000' }}>{data.Shipping_Charg === 0 ? "Free" : parseFloat(data.Shipping_Charg).toFixed(2)}</td>
                              </tr>
                              <tr style={{ fontSize: '14px', borderBottom: '2px solid #000' }}>
                                {/* <td style={{ paddingTop: '4px', paddingBottom: '4px', color: '#000', paddingRight: '2%', textAlign: 'right', fontWeight: '800', borderRight: '2px solid #000' }}>Taxes</td>
                                <td style={{ paddingTop: '4px', paddingBottom: '4px', color: '#000', paddingRight: '2%', textAlign: 'right', fontWeight: '600' }}>
                                  {parseFloat(data.fld_total_tax).toFixed(2)}
                                </td> */}
                                <td style={{ paddingTop: '4px', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', fontWeight: '800', color: '#000', borderRight: '2px solid #000' }}>Coupon  {data.fld_coupon_code !== null ? - (data.fld_coupon_code) : ""}</td>
                                <td style={{ paddingTop: '4px', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', color: '#000', fontWeight: '600' }}>{data.fld_coupon_discount === null ? "-" : parseFloat(data.fld_coupon_discount.toFixed(2))}</td>
                              </tr>
                              <tr style={{ fontSize: '14px' }}>
                                <td rowSpan='4' style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000' }}>
                                  <td rowSpan="4" style={{ paddingLeft: '1px', textAlign: 'left', border: "none", color: '#000', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000', width: '100px' }}>Tax Breakup</td>
                                  {this.state.ClientStateId === this.state.OrderState ?
                                    <>
                                      <tr style={{ fontSize: '14px' }}>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '5px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000', borderBottom: '2px solid #000' }}>SGST</td>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', borderBottom: '2px solid #000', paddingRight: '4px' }}>{parseFloat(data.fld_total_tax).toFixed(2) / 2}</td>
                                      </tr><tr style={{ fontSize: '14px' }}>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000' }}>CGST</td>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', paddingRight: '4px' }}>{parseFloat(data.fld_total_tax).toFixed(2) / 2}</td>
                                      </tr>
                                      <tr style={{ fontSize: '14px' }}>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000' }}>IGST</td>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', paddingRight: '4px' }}>-</td>
                                      </tr></>
                                    : <><tr style={{ fontSize: '14px' }}>
                                      <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '5px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000', borderBottom: '2px solid #000' }}>SGST</td>
                                      <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', borderBottom: '2px solid #000', paddingRight: '4px' }}>-</td>
                                    </tr><tr style={{ fontSize: '14px' }}>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000' }}>CGST</td>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', paddingRight: '4px' }}>-</td>
                                      </tr>
                                      <tr style={{ fontSize: '14px' }}>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', width: '94px', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', borderRight: '2px solid #000', borderTop: '2px solid #000' }}>IGST</td>
                                        <td colSpan="3" style={{ paddingLeft: '4px', textAlign: 'left', border: "none", color: '#000', paddingTop: '4px', paddingBottom: '4px', verticalAlign: 'top', fontWeight: '600', justifyContent: 'end', display: 'flex', paddingRight: '4px', borderTop: '2px solid #000' }}>{parseFloat(data.fld_total_tax).toFixed(2)}</td>
                                      </tr></>
                                  }
                                </td>

                              </tr>
                              <tr style={{ fontSize: '14px' }}>
                                <td style={{ paddingRight: '2%', paddingTop: '4px', paddingBottom: '4px', textAlign: 'right', fontWeight: '900', borderRight: '2px solid #000', fontSize: "15px", color: '#000', borderBottom: '2px solid #000' }}>Total Amount INR <span style={{ fontSize: '13px', fontWeight: '600' }}>(Rounded)</span> <span className="d-block" style={{ fontSize: '13px', fontWeight: '600' }}>(Including {parseFloat(data.fld_total_tax).toFixed(0)} Taxes)</span></td>
                                <td style={{ paddingRight: '2%', textAlign: 'right', borderBottom: '2px solid #000', fontWeight: '900', fontSize: "14px", color: '#000' }}>{parseFloat(data.Total_Inclusive_Tax).toFixed(2)}</td>
                              </tr>
                              <tr style={{ fontSize: '14px' }}>
                                <td style={{ paddingTop: '4px', borderRight: '2px solid #000', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', fontWeight: '800', color: '#000' }}>Payment Mode</td>
                                <td style={{ paddingTop: '4px', paddingBottom: '4px', paddingRight: '2%', textAlign: 'right', color: '#000', fontWeight: '600' }}>{data.Payment_Mode}</td>
                              </tr>
                            </tbody>
                          )
                        }
                      })}
                    </table>
                  </Card>
                  <Card>
                    <CardBody className="py-1  my-1">
                      <Row>
                        <Col xs="12">
                          <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6">
                              <button
                                style={{ float: "right", backgroundColor: "#777f80", color: "#fff" }}
                                className="btn align-items-center btn globalTrendzEcomm-btn"
                                onClick={() => {
                                  var item = ''
                                  for (var i = 0; i < this.state.SelectedProduct.length; i++) {
                                    if (i == 0) {
                                      item = this.state.SelectedProduct[i].fld_order_itemid
                                    } else {
                                      item =
                                        item + ", " + this.state.SelectedProduct[i].fld_order_itemid
                                    }
                                  }
                                  if (this.state.SelectedProduct.length > 0) {
                                    this.onPost()
                                  } else {
                                    Notiflix.Notify.Failure('Please select item for dispatch.')
                                  }
                                }}
                              >
                                Dispatch
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Dispatch;
